.topicContainer {
  background-color: #fff;
  padding: 50px 0px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  @media (max-width: 1024px) {
    padding: 48px 0px;
  }
}

body.langdirection-rtl {
  .topicContainer {
    background-color: #fff;
    padding: 50px 0px;
    direction: rtl;
    text-align: right;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    @media (max-width: 1024px) {
      padding: 48px 0px;
    }
  }

}