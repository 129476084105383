.text {
  color: black;
  font-family: 'Segoe UI';
}

.heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 18.62px
    // padding-bottom: 2px;
}

.main-heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
 // padding-bottom: 2px;
  transition: all 0.3s ease;
}

.banner-button {
    height:50px;
    cursor: pointer;
    border: none;
    width: fit-content;
    max-width: 250px;
    overflow: 'hidden';
    text-overflow: ellipsis;
    background-color: #005DAC;
    margin-top: 18px;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    padding: 25px 10px;

    span {
        font-size: 16px !important;
    }

    &:focus {
        outline: none !important;
        border-radius: 8px;
    }

    &:focus::after {
        outline: none !important;
        border-radius: 8px;
        border: 2px solid black !important;
    } 
}
    .image-container {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        //    width :1600px;
        //height: 368px;
        display: flex;
    }

    .description {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        width: 55%;
    @media (max-width:"768px"){
        width:unset !important;
        }
    }

    .banner-section {
        background: #F6F6F6;
    }

    .banner-container {
        position: relative;
        //  max-width: 1600px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        transition: all 0.3s ease;


        .slide-container {
            position: relative;
            width: 100%;
  
        }
    }
    .text-container {
        //  position: absolute;
        top: 185px;
        // left: 25.7%;
        //   transform: translate(-50%, -50%);
        text-align: left;
        color: black;
        //width: 40%;
        //   transition: all 0.3s ease;
        display: flex;
        @media screen and (min-width: 769px) and (max-width: 820px) {
            //     width: 43%;
        left:28%;
        }
    }
    body.langdirection-rtl {
        .text-container {
            //  position: absolute;
            top: 185px;
            // left: 25.7%;
            //   transform: translate(-50%, -50%);
            text-align: left;
            color: black;
            //width: 40%;
            //   transition: all 0.3s ease;
            display: flex;
            //right align for arabic
            width: 60%;
            text-align: right;
            direction: rtl;
            @media screen and (min-width: 769px) and (max-width: 820px) {
                //     width: 43%;
            left:28%;
            }
        }
        @media screen and (max-width: 768px) {
            .text-container {
                position: static !important;
                transform: none !important;
                width: fit-content !important;
                text-align: right !important;
                direction: rtl;
                height: fit-content;
                max-width: 2048px;
                margin: 30px 0px 30px 0px;
            }
        }

    }
    .banner-background:hover .arrow-button,
    .banner-background:focus .arrow-button {
        background-color: #2e2e2e;
        opacity: 0.7;
        border-radius: 50%;
    }

    @media screen and (max-width: 1850px) {
        .banner-container {
            //  padding: 0% 6%;
        }
    }

    @media (max-width: 1200px) {
        .main-heading {
            font-size: 32px;
        }
    }

    @media screen and (max-width: 768px) {


        .text-container {
            position: static !important;
            transform: none !important;
            width: fit-content !important;
            text-align: left !important;
            height: fit-content;
            max-width: 2048px;
            margin: 30px 0px 30px 0px;
        }

        .heading {
            font-size: 16px;
        }

        .main-heading {
            font-size: 26px;
        }

        .slick-dots {
            position: static !important;
            bottom: -28px !important;
        }

        .button-container {
            justify-content: center !important;

            button {
                padding: 20px 10px !important;
            }

            a {
                button {
                    padding: 20px 10px !important;
                }
            }
        }

      
    }


    .video {
    background-color:  #28821B;
        color: white !important;
        left: 7px;
    }
    @media screen and (max-width: 640px) {
        .banner-container {
            //   padding: 0% 2%;

        }
    }



    @media (max-width: 1024px) {
  .banner-section{

            // padding: 48px 0px;
        }
    }

    .people-section {
        position: relative;
        //   height: 275px;
        //  flex: none;
        //  justify-content: left;
        // left: 50%;
        //  width: Hug (465.78px)px;
        //  height: Hug (276px)px;
        //  right: 8%;
        //  top: 55px;
    }

    @media screen and (min-width:769px) and (max-width: 980px) {
        .people-section {
            right: 1% !important;

            img {
                width: 375px !important;
            }
        }
    }

    @media (max-width: 768px) {
        .people-section {
     display:none;
        }

        .image-container {

            //display: none;
        }

    }


    .image-container {
        //   display: block;
    }

    .mobile-image-container {
        display: none;
    }

    @media (max-width: 768px) {


        .mobile-image-container {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .bannerImgMob {

        @media (max-width: 768px) {
        display: block ;
            height: 100%;
            width: 100%;
        }

        @media (min-width: 769px) {
            display: none !important
        }
    }

    .bannerImg {

        @media (max-width: 768px) {
            display: none;
            height: 100%;
            width: 100%;
        }

        @media (min-width: 769px) {
            display: block !important
        }
    }


    .banner-div {
        width: 100%;
        justify-content: space-between;
        margin: 14rem 0rem;
        @media (max-width: 768px) {
            margin: 0rem 0rem !important
        }
    }