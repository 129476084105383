.ai {
    background: linear-gradient(180deg, #003157 4.66%, #004275 100%);
    position: relative;
    //height: 185px;
    gap: 0px;
    opacity: 1;
    padding: 3rem 0;

    .text {
        color: #FFFFFF;
        font-family: 'Segoe UI';
    }

    .main-heading {
        font-family: Segoe UI;
        font-size: 36px;
        font-weight: 600;
        line-height: 47.88px;
        text-align: left;
    }


    .description {
        font-family: Segoe UI;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.28px;
        text-align: left;
        width: 100%;
    }

    .content-container {
        display: flex;
        justify-content: space-between;
        /* Space between left and right columns */
        align-items: flex-start;
        /* Align items to the top */
        width: 100%;
    }

    .width-50 {
        flex: 1;
        /* Make each column take up equal width */
        max-width: 50%;
        /* Ensure columns don't exceed 50% of the width */
        display: flex;
        flex-direction: column;
        /* Stack elements vertically */
        align-items: flex-start;
        /* Align items to the start (left) */
        padding: 1% 0 1% 0;
    }

    .formdiv {
        align-items: flex-end;
    }

    .ai-assistant-text {
        margin-top: 10px;
    }

    .ai-assistant {
        background: linear-gradient(180deg, #003157 4.66%, #004275 100%);
        position: relative;
        height: 185px;
        gap: 0px;
        opacity: 1;
    }

    .pt-25 {
        padding-top: 2.5%;
    }

    .input-style {
        width: 100%;
        padding: 10px;
        padding-right: 50px;
        gap: 0px;
        border-radius: 8px;
        border-bottom: 3px solid #6CEBE8;
        opacity: 1;
        position: relative;
    }

    .input-button {
        position: absolute;
        //   transform: translateY(-150%); /* Center the image vertically */
        right: 8.6%;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0 10px;
        /* Optional: Adjust padding to fit the image */
    }

    .input-button img {
        height: 100%;
        /* Scale the image to fit the height of the input */
        border-radius: 0 8px 8px 0;
        /* Round only the right corners */
    }

    .button-container {
        display: flex;
        gap: 10px;
        /* Space between the buttons */
        margin-top: 10px;
    }

    .transparent-button {
        background-color: transparent;
        color: #FFFFFF;
        padding: 10px 10px;
        border-radius: 8px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        border: 1px solid #FFFFFF;

        &:focus::after {
            outline: none !important;
            border-radius: 8px;
            border: 2px solid black;
        }
    }

    .transparent-button:hover {
        background-color: #007BFF;
        color: white;
    }

    @media screen and (max-width: 1850px) {
        .pl-5 {
            //padding: 1% 0 1% 5%;
        }
    }

    @media (max-width: 1200px) {
        .main-heading {
            font-size: 32px;
        }
    }

    @media screen and (max-width: 768px) {

        .heading {
            font-size: 16px;
        }

        .main-heading {
            font-size: 26px;
        }

        .button-container {
            justify-content: unset !important;
        }

        .banner-button {
            background-color: #fff;
            color: #000;
        }
    }

    @media screen and (max-width: 640px) {
        .banner-container {
            padding: 0% 2%;
        }
    }



    @media (max-width: 1024px) {
        .banner-section {
            // padding: 48px 0px;
        }
    }

    .people-section {
        position: absolute;
        height: 275px;
        flex: none;
        justify-content: left;
        top: -1.5rem;
        width: Hug (465.78px)px;
        height: Hug (276px)px;
        left: 700px;
    }

    @media screen and (max-width: 1280px) {
        .people-section {
            left: 30rem;
        }
    }

    @media (max-width: 769px) {
        .people-section {
            display: none;
        }

        .image-container {
            display: none;
        }
    }
}

.form-input-area {
    top: 2px;
    //   background-color: white !important;
    //  border: none !important;
    // height: 50px;


}

.form-send-button {
    width: 20px;
    height: 20px;
    position: relative;
    color: black;
    top: 1.2rem;
    right: 1%;
    position: absolute;

    i {
        font-size: 20px !important;
    }
}

.form-input {
    margin-left: 10px;
    width: 100%;
    position: relative;

    span {
        border-radius: 8px;
    }
}

input {
    padding: 18px 35px 18px 15px;
    font-size: 14px !important;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 3px solid #6CEBE8;
    border-radius: 8px;

}

@media (max-width:768px) {
    .form-ai {
        display: block !important;
    }

    .width-50 {
        max-width: unset !important;
    }
}

body.langdirection-rtl {
    .ai {
        .main-heading {
            font-family: Segoe UI;
            font-size: 36px;
            font-weight: 600;
            line-height: 47.88px;
            text-align: right;
        }

        .description {
            font-family: Segoe UI;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.28px;
            text-align: right;
            width: 100%;
        }

    }

    .width-50 {
        flex: 1;
        /* Make each column take up equal width */
        // max-width: 50%; /* Ensure columns don't exceed 50% of the width */
        display: flex;
        flex-direction: column;
        /* Stack elements vertically */
        align-items: flex-start;
        /* Align items to the start (left) */
        padding: 1% 0 1% 0;
        //right align for arabic
        max-width: 60%;
        text-align: right !important;
        direction: rtl !important;
    }

    .rightpadding {
        margin-right: 5%;
    }

    @media (max-width:768px) {
        .form-ai {
            display: block !important;
        }

        .width-50 {
            max-width: unset !important;
        }
    }
    .form-send-button {
        width: 20px;
        height: 20px;
        position: relative;
        color: black;
        top: 1.2rem;
        left: 1% !important;
        right: unset !important;
        position: absolute;

        i {
            font-size: 20px !important;
        }
    }

    input {
        padding: 18px 15px 18px 15px !important;
        font-size: 14px !important;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 3px solid #6CEBE8;
        border-radius: 8px;
    }
}