.chatbot {
	font-family: monospace;
	border-radius: 10px;
	display: flex;
	flex-flow: column;
	margin-top:15px;
}

.messages {
	width: 100%;
	overflow: auto;
	display: flex;
	text-align: left;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;

}
.message-container {
	/*display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;*/
}

.bot-message-hi {
	//align-self: flex-end;
	float: left;
	padding: 15px 15px;
	margin: 5px;
	//	border: 1px solid #DADADA;
	border-radius: 8px;
	background: white;
	color: black;
	//	width: 85%;
	//box-shadow: 0px 1px 3.8px 0px #00000040;
}

.bot-msg {
	float: left;
	//margin: 5px;
	//border: 1px solid #DADADA;
	border-radius: 8px;
	background: white;
	color: black;
	width: 98%;
//	box-shadow: 0px 1px 3.8px 0px #00000040;
}
.response {
	padding: 15px 15px;
}

.user-message {
	float: right;
	padding: 15px 15px;
	margin: 10px;
	border-radius: 20px 20px 1px 20px;
	max-width: 89%;
	background: #B0D5F2;
	border-radius: 8px;
	color: black;
}

.input {
	display:flex;
}

.input > form > input[type="text"] {
	font-family: monospace;
	font-size: 16px;
	border: 1px solid lightgrey;

	opacity: 1;
	padding: 16px 52px 16px 10px;
	-webkit-appearance: none;
}

.input > form > input:focus {
	outline: none;
}

.input > form > button {
	background-color: transparent;
	cursor: pointer;
	opacity: 1;
	padding: 14px 16px 12px 16px;
	position: absolute;
	right: 15px;
	top: 11px;
}
.send-button {
	position: relative;
	top: 4px;
}

.bot-icon {
	width: 32px;
	height: 32px;
	border-radius:20px;
}
.bot-input {
	margin-left: 10px;
	width: 100%;
	height: 106px;
	background-color: white !important;
	border: 1px solid #D6D6D6;
	border-radius: 8px;
}
.input-area {
	top: 2px;
	background-color: white !important;
	border: none !important;

}
/*.panel-footer{
	position: fixed;
	bottom: 10px;
}*/

.security-text {
	font-family: Segoe UI;
	//font-size: 13px;
	font-weight: 500;
	line-height: 17.29px;
	text-align: left;
	margin-bottom: 15px;


}
.bot-security-text {
	display:flex;
	font-family: Segoe UI;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.6px;
	text-align: left;
	margin-bottom: 15px;
	color: black;
}

.input-security-text {
	font-family: Segoe UI;
	font-size: 13px;
	font-weight: 500;
	line-height: 17.29px;
	text-align: left;
	margin-bottom: 15px;
	color: #00990F;
}
.security-icon{
	position:relative;
	top:3px;
	margin-right:10px;
}
inputContainer {
	padding: 10px;
	border-top: 1px solid #ccc;
	background-color: #fff;
	position: sticky;
	bottom: 0;
	z-index: 2;
}

.data-icon {
	position: relative;
	top: 6px;
	margin-right: 6px;
}

.data-protection-icon {
	position: relative;
	top: 3px;
	margin-right: 9px;
	width: 26.44px;
	height: 26.44px;
	border-radius: 4px 0px 0px 0px;

}
.accept-icon {
	position: relative;
	margin-right: 8px;
	width: 18px;
	height: 18px;
	font-size: 18px;
	color: #00990F;
}
.accept-text {
	display: flex;
	color: black;
	font-family: Segoe UI;
	font-weight: 400;
	line-height: 17.29px;
	text-align: left;
	margin-bottom: 15px;

	.text {
		font-size: 14px !important;
		color: black;
		background-color: white;
		padding: 15px;
		border-radius: 8px;
		margin-bottom:20px;
	}
}

.feedback {
	border-top: 1px solid #DADADA;
	background-color: white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}
.feedback-section {
	//padding: 10px;
	border: 1px solid #ABABAB ;
	width: 64px;	
	border-radius: 4px ;
	color:black;
	display:flex;
}
.feedbackicon {
	position: relative;
	//margin-right: 4px;
	font-size: 16px;
	color: black;
	font-weight: 'bold';
}
.longText {
	text-align: center !important;
	width: 100%;
	font-family: Segoe UI;
	font-size: 14px;
	font-weight: 600 !important;
	line-height: 18.62px;
	text-align: left;
	color: #0078D4 !important;
	border: 1px solid black !important;
	border-radius: 0px !important;
}

.followUp-section{
	margin:5px;
}
.question{
	margin-bottom:5px;
}

.feedbackicon.active {
	color: white;
	background-color: #3873E2;
}

.stop-res {
	border: 1px solid #1648E1 !important;
	color: #1648E1 !important;

	span {
		width: 16px;
		height: 16px;
		border-radius: 2px 0px 0px 0px;
		background-color: #1648E1;
		margin-right:5px;
	}
}

.respond{
	text-align:center;
	margin-top:5rem;
}
.msg {
	word-wrap: break-word;

	.link {
		color: #0078D4;
		text-decoration: underline;
		font-size: 14px;
		line-height: 23px;
		font-weight: 400;
		margin-left: 3px;
		margin-right: 3px;
	}

	a {
		color: #0078D4 !important;
	}

	.plan-title {
		font-family: Segoe UI;
		font-size: 14px;
		font-weight: 700;
		line-height: 18.62px;
		text-align: left;
	}

	.plan-sub {
		margin-top: 7px;
		font-family: Segoe UI;
		font-size: 14px;
		font-weight: 400;
		line-height: 18.62px;
		text-align: left;
	}
}

.new-session {
	font-weight: 700;
	text-align: center;
	padding: 15px 15px;
	margin: 5px 0px 12px 0px;
	//border-bottom: 1px solid #DADADA;
	background: white;
	color: black;
	width: 90%;
	border-radius:8px;
}

.timestamp-user {
	
	font-size: 12px;
	font-weight: 400;
	line-height: 15.96px;
	text-align: right;
	color: #6A6768;
	margin:5px 10px 10px 10px;
}
.timestamp-bot {
	font-size: 12px;
	font-weight: 400;
	line-height: 15.96px;
	text-align: left;
	color: #6A6768;
	margin: 5px 10px 10px 5px;
}

.follow-up {
	font-family: Segoe UI;
	font-size: 14px;
	font-weight: 600;
	line-height: 18.62px;
	text-align: left;
	margin: 15px 15px 0px 15px !important;
}

i{
	display:none;
}
.feedback {
    display: flex;
    align-items: center; 
}

.feedback-question {
    margin-right: 10px; 
}

.feedback-items {
    display: flex;
    align-items: center; 
}

.feedback-item {
    display: flex;
    align-items: center; 
    margin-left: 10px; 
}
.feedback-text{
	font-weight: bold;
	padding-left: 3px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%; /* Responsive width */
    max-width: 400px; /* Max width for larger screens */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	color: black;
}

.modal-header {
    display: flex;
    justify-content: flex-end; /* Aligns items to the right */
}

.close-button {
    background: none;
    border: none;
    font-size: 16px; /* Adjust size as needed */
    cursor: pointer;
    color: #333; /* Adjust color as needed */
    margin-left: auto; /* Push the button to the right */
}

.feedback-button {
	height: 0px;
	cursor: pointer;
	border: none;
	width: fit-content;
	max-width: 200px;
	overflow: "hidden";
	text-overflow: ellipsis;
	background-color: rgb(243, 242, 241);
	margin-top: 18px;
	color: black;
	border-radius: 8px;
	font-size: 16px;
}

.feedback-button.active {
	background-color: #005DAC;
	color: white;
}

.textarea {
    width: 100%;
    height: 100px; 
    overflow-y: auto;
    resize: none; 
    padding: 8px; 
    box-sizing: border-box; 
}
.active {
    border-left: 0px solid #005597 !important;

    @media (max-width: 1024px) {
        border-left: none !important;
    }
}

.limit {
	text-align: right;
	color: #767676;
}

body.langdirection-rtl {
	.bot-input {
		direction: rtl;
		text-align: right;
		margin-left: 0px !important;
		//	margin-right: 10px;
	}

	.limit {
		
		text-align: left;
		color: #767676;
	}

	.buttonText {
		//direction: rtl;
		//text-align: right;
	}

	.headerContainer {
		direction: rtl;
		text-align: right;
	}

	.follow-up {
		text-align: right !important;
	}

	.msg {
		.plan-title {
			text-align: right !important;
		}

		.plan-sub {
			text-align: right !important;
		}
	}

	.bot-msg {
		direction: rtl;
		text-align: right !important;
	}

	.timestamp-bot {
		direction: rtl;
		text-align: right !important;
	}

	.message-container {
		direction: rtl;
		text-align: right !important;
	}

	.accept-text {
		direction: rtl;
	}
}
	.buttonText {
		color: #1A1A1A;
		font-size: 18px;
		margin-left: 20px;
		margin-right: 20px;
		line-height: 24px;
		font-weight: 700 !important;
	}


	.headerContainer {
		padding-top: 15px;
		padding-bottom: 15px;
		border-bottom: 1px solid #DDDDDD;
		box-shadow: 0px 0px 4px 0px #00000040;
	}

