.tab-List {
    height: 53px;
    display: flex;
    padding: 4px;
}

.is-active {
    border-bottom: 4px solid #0074D3 !important;
    // color: #242424;
    font-weight: 700;
}

.nav-item {
    height: 100%;
    //padding: 0px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #004275;
    margin-right: 2rem;
    font-weight: 600;
}

.mobile-sticky-nav {
    display: none;
}

@media screen and (min-width:769px) {
    .nav-item-mobile {
        display: none;
    }
}

@media screen and (max-width:768px) {
    .mobile-sticky-nav {
        display: flex;
    }
}

.iaccessible {
    i {
        display: none !important;
    }
}

body.langdirection-rtl {
    .tab-List {
        height: 53px;
        display: flex;
        padding: 4px;
        direction: rtl;
        text-align: right;
    }
  
    @media screen and (max-width:768px) {
        .mobile-sticky-nav {
            display: flex;
            direction: rtl !important;
            text-align: right !important;
        }
    }

    @media screen and (max-width:768px) {
        .nav-item-mobile {
            direction: rtl !important;
            text-align: right !important;
        }
    }

}