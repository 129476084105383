.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Segoe UI';
    transition: margin-right 0.3s ease;
}

.shifted {
    margin-right: 400px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3{
  font-size: 24px;
}

p{
  font-size: 14px;
}

span {
  font-size: 12px;
}

button {
  cursor: pointer;
}

.fxugw4r{
  background-color: #f6f6f6 !important;
}


body.langdirection-rtl .buttonDiv {
    bottom: 0px;
    left: 20px !important;
    position: fixed;
    right:unset !important;
}


.buttonDiv {
    bottom: 0px;
    right: 20px;
    position: fixed;
}

