.spotlightContainer {
  background-color: #fff;
  padding: 50px 0px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }

  @media (max-width: 1024px) {
    padding: 48px 0px;
  }
}
body.langdirection-rtl {
    .spotlightContainer {
        background-color: #fff;
        direction: rtl; // Right align Arabic
        text-align: right;
        padding: 50px 0px;
      
        @media (max-width: 768px) {
          margin-top: 0px;
        }
      
        @media (max-width: 1024px) {
          padding: 48px 0px;
        }
      }
}