.language-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 languages per row */
  row-gap: 1rem;
  column-gap: 10rem;
  margin-top: 20px;
  padding-right: 5%;
  padding-left: 9%;
  width: 90%;
  box-sizing: content-box;
}

.language-item {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #0067b8;
}

.language-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  /* Adjust gap between items as needed */
}

@media (max-width: 1250px) {
  .language-grid {
    grid-template-columns: 1fr;
    /* Change to a single column */
    gap: 1rem;
  }
}