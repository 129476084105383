
   .linkButton {
    position: relative;
    left: 10px; // Change to right for Arabic
    width: 30px !important;
    height: 30px !important;
    border-radius: 6px;
    background-color: #0078D4;
  }
  .linkinButton
  {
    right:15px;
}
  .linkCategory {
    left: 0px !important;
    margin-left: 7px;
}
.linkText {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 5px;
  left: 17px;
  white-space: nowrap;
  line-height: 23px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #686868;
  overflow: hidden;
  text-overflow: ellipsis;
}

body.langdirection-rtl {
    .linkButton {
        position: relative;
        right: 0px !important;
        width: 30px !important;
        height: 30px !important;
        border-radius: 6px;
        background-color: #0078D4;
    }
      .linkinButton
      {
        left:15px;
        right: 0px;
    }
    .linkCategory {
        right: 0px !important;
        margin-right: 0px !important;
        left: 0px  !important;
        margin-left: 17px !important;
    }
    .linkText {
      direction: rtl;
      text-align: right;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 5px;
      right: 10px !important;
      left: unset!important ;
      white-space: nowrap;
      line-height: 23px;
      letter-spacing: -0.03em;
      color: #686868;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  

    .cardRight {
        direction: rtl;
        text-align: right;
    }

    .cardContent {
        direction: rtl;
        text-align: right;
    }

    .cardButton {
        right: 12px !important;
    }

    .categoryText{
        text-align:right;
        right:17px;
    }
}