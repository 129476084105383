.storyHeading {
    button {
        font-family: Segoe UI;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        padding-left: 0px;
    }

    span {
        justify-content: unset !important;
    }
}

.storyDescription {
    font-family: Segoe UI;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.47999998927116394px;
    margin: 0px 0px !important;
}

.item {
    padding: 17px 0px 17px 0px;
    border-bottom: 1px solid #BDC5D2;
    margin-left: 1.9rem;

    @media (max-width: 1024px) {
        margin-left: 0px !important;
    }
}

.link-div {
    padding-bottom: 10px;

}

.accordion-item {
    transform: scaleY(1);
    transition: opacity, transform .8s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        //  transform: translateX(100%);
    }

    to {
        opacity: 1;
        //  transform: translateX(0);
    }
}

.effect {
    animation: fadeIn 0.5s ease-in-out;
}

.story-link {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-decoration: none;
    color: #0074D3 !important;

    &:focus-visible {
        text-decoration-style: solid !important;
    }
}

.active {
    border-left: 3px solid #005597;

    @media (max-width: 1024px) {
        border-left: none !important;
    }
}

.story-link-icon {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-decoration: none;
    position: relative;
    top: 0px;
    left: 4px;
    right: 0px;
    color: #0074D3 !important;

    &:focus-visible {
        text-decoration-style: solid !important;
    }
}

.detailsDiv {
    padding: 0rem 2rem 0 2rem;

    @media (max-width: 1400px) {
        padding: 0rem 2rem 0 2rem;
    }

    @media (max-width: 1024px) {
        padding: 0rem !important;
    }
}

body.langdirection-rtl {
    .detailsDiv {
        padding: 0rem 2rem 0 2rem;
        direction: rtl;
        text-align: right;

        @media (max-width: 1400px) {
            padding: 0rem 2rem 0 2rem;
        }

        @media (max-width: 1024px) {
            padding: 0rem !important;
        }
    }

    .story-link-icon {
        font-size: 16px !important;
        font-weight: 600 !important;
        text-decoration: none;
        position: relative;
        top: 0px;
        right: 4px;
        left: 0px;
        color: #0074D3 !important;

        &:focus-visible {
            text-decoration-style: solid !important;
        }
    }
    .storyHeading {
        button {
            font-family: Segoe UI;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: right;
            padding-left: 0px;
        }
    }    

}