.header {
    //  height: 54px;

    .c-uhfh.c-sgl-stck .c-button-logo {
        z-index: 0;
    }


    .c-uhfh > div:first-child .c-logo {
            padding: 16px 10px 16px 0px !important;
    }

    .c-uhfh .c-show-pipe::before {
        margin-left: 2px !important;
    }
}
@media only screen and (max-width: 859px){
  .header {
    height: auto;
    }
}
.bot-div {
    background: #f7f7f7;
    height: 64px;
}






